<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="관련공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="SOP_FIRE_FIGHTING_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="sopFireFightingTypeCd"
            label='유형'
            v-model="searchParam.sopFireFightingTypeCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-card title="소방설비 배치도" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="추가" v-if="editable" icon="add" @btnClicked="onItemClick" />
          <c-btn label="삭제" v-if="editable && grid.data.length > 0" icon="remove" @btnClicked="removeClick"/>
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="data.maps"
            mappingType="POST"
            label="저장" 
            icon="save" 
            @beforeAction="saveClick"
            @btnCallback="saveCallback"/>
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container" ref="carousel">
            <q-carousel
              v-if="data.maps && data.maps.length > 0"
              class="map-carousel"
              control-type="regular"
              control-color="grey-6"
              animated
              arrows
              v-model="data.slide"
              infinite
              @transition="transition"
            >
              <q-carousel-slide 
                v-for="(map, idx) in data.maps"
                :key="idx"
                :name="idx" 
                :img-src="map.mapSrc"
                >
                <template>
                  <div class="mapTitleDiv" v-text="map.mapName"></div>
                  <div class="q-img__content absolute-full"> 
                    <VueDraggableResizable
                      v-for="(item, idx) in map.maps"
                      :key="idx"
                      ref="markImage"
                      class="mainMarkImage"
                      :resizable="false"
                      :parent="true"
                      :draggable="true"
                      :x="item.locationXcoordinate"
                      :y="item.locationYcoordinate"
                      :w="30" :h="30"
                      :grid="[30, 30]"
                      @dragging="(x, y) => onDrag(item, x, y)"
                    >
                      <q-img fit="scale-down"
                        style="bottom: 5px;" :contain="true">
                      </q-img>
                      <!-- <q-icon name="check" color="blue" class="blinking" /> -->
                          <!-- top: getTop(item), -->
                      <div class="markImage-abbreviation-parent"
                        :style="{ 
                          width: '220px', 
                          left: getLeft(item),
                        }">
                        <div class="markImage-abbreviation" 
                          @mouseover="() => { item.isHover = true }"
                          @mouseleave="() => { item.isHover = false }">
                          <transition name="mark-list" tag="div">
                            <div>
                              <q-banner
                                dense 
                                :class="[idx % 2 ===1 ? 'bg-grey-3' : 'bg-grey-1', 'markImage-abbreviation-banner-detail']">
                                <template v-slot:avatar>
                                  <q-icon name="warning" class="text-red" size='xs' />
                                </template>
                                <div class="caption-header">
                                  {{item.fireFightingName}}
                                </div>
                                <div class="caption-work">
                                  {{ '관리번호: ' + item.manageNo }}
                                  <br>
                                </div>
                              </q-banner>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </VueDraggableResizable>
                  </div>
                </template>
              </q-carousel-slide>
            </q-carousel>
            <el-empty v-else 
              :image-size="435"
              description="등록된 지도가 없습니다."></el-empty>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 container">
            <c-table
              ref="table"
              title="소방설비 목록"
              :isTitle="false"
              selection="multiple"
              :columns="grid.columns"
              :data="grid.data"
              rowKey="sopFireFightingId"
            >
            </c-table>
          </div>
        </template>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
export default {
  name: 'fire-fighting-layout',
  components: { VueDraggableResizable },
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        deptCd: null,
        processCd: null,
        sopFireFightingTypeCd: null,
        disposeFlag: 'N'
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'manageNo',
            field: 'manageNo',
            label: '관리번호',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소방설비',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
          },
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: '유형',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'processName',
            field: 'processName',
            label: '관련공정',
            align: 'left',
            sortable: true,
            style: 'width: 350px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '관리부서',
            align: 'left',
            sortable: true,
            style: 'width: 350px',
          },
        ],
        data: [],
        height: '500px'
      },
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      maps: [],
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    mapRate() {
      return 0.835
    },
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.fft.fireFightingMap.list.url
      this.listMapUrl = selectConfig.swp.map.list.url
      this.saveUrl = transactionConfig.fft.fireFightingMap.save.url;
      this.deleteUrl = transactionConfig.fft.fireFightingMap.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      let _height = 575 * this.mapRate
      this.$refs['carousel'].style.setProperty("--map-height", String(_height) + 'px');
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (_result.data.length > 0) {
          this.data.maps = _result.data
          if (this.data.maps && this.data.maps.length > 0) {
            this.grid.data = this.data.maps[this.data.slide].mapDetails;
          } else {
            this.grid.data = [];
          }
          this.$refs['carousel'].style.setProperty("--map-ratio", this.data.maps[0].ratio);
        } else {
          this.grid.data = [];
          this.data = {
            slide: 0,
            mapSrc: require('@/assets/images/no-image.png'),
            fullscreen: false,
            maps: [],
          }
        }
      },);
    },
    transition() {   
      this.grid.data = this.data.maps[this.data.slide].mapDetails;
    },
    onDrag(item, x, y) {
      this.realKey = item.sopFireFightingId

      this.$_.forEach(this.data.maps[this.data.slide].maps, _item => {
        if (_item.sopFireFightingId === item.sopFireFightingId) {
          if (_item.editFlag !== 'C') {
            _item.editFlag = 'U'
            _item.chgUserId = this.$store.getters.user.userId
          }
        }
      })
      this.$set(item, 'locationXcoordinate', x)
      this.$set(item, 'locationYcoordinate', y)
    },
    getWidth() {
      let width = 0;
      return width + 'px'
    },
    getLeft(item) {
      /**
       * 지도의 크기는 800
       */
      let left = 0;
      let nonDetailLocation = item.locationXcoordinate + 210;
      let detailLocation = item.locationXcoordinate + 210 
      let calLocation = nonDetailLocation > detailLocation ? nonDetailLocation : detailLocation
      if (calLocation > 800) {
        left = 800 - calLocation
      }
      return left + 'px'
    },
    getTop(item) {
      /**
       * 지도의 높이는 594
       */
      let top = 29;
      let calLocation = item.locationYcoordinate;
      if (calLocation > 594) {
        top = 594 - calLocation
      }
      return top + 'px'
    },
    onItemClick() {
      this.popupOptions.title = '소방설비 검색'; // 소방설비 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./fireFightingPop'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let index = -1;
          this.$_.forEach(this.data.maps, _item => {
            if (index === -1) {
              index = this.$_.findIndex(_item.maps, { sopFireFightingId: item.sopFireFightingId })
            }
          })
          if (index === -1) {
            this.data.maps[this.data.slide].maps.push({
              plantName: item.plantName,
              sopFireFightingId: item.sopFireFightingId,
              fireFightingName: item.fireFightingName,
              sopFireFightingTypeName: item.sopFireFightingTypeName,
              manageNo: item.manageNo,
              processName: item.processName,
              deptName: item.deptName,
              locationXcoordinate: 0,
              locationYcoordinate: 0,
              sopMapId: this.data.maps[this.data.slide].sopMapId,
              editFlag: 'C',
            })
            this.data.maps[this.data.slide].mapDetails.push({
              plantName: item.plantName,
              sopFireFightingId: item.sopFireFightingId,
              fireFightingName: item.fireFightingName,
              sopFireFightingTypeName: item.sopFireFightingTypeName,
              manageNo: item.manageNo,
              processName: item.processName,
              deptName: item.deptName,
            })
          }
        })
      }
    },
    saveClick() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$_.forEach(this.data.maps, item => {
            item.regUserId = this.$store.getters.user.userId;
            item.chgUserId = this.$store.getters.user.userId;
          });
          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    removeClick() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.$_.forEach(this.data.maps, _item => {
                _item.maps = this.$_.reject(_item.maps, {sopFireFightingId: item.sopFireFightingId})
                _item.mapDetails = this.$_.reject(_item.mapDetails, {sopFireFightingId: item.sopFireFightingId})
              })
            })
            this.grid.data = this.data.maps[this.data.slide].mapDetails;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
  }
};
</script>
<style lang="sass">
.markImage
  padding: 0px !important
  text-align: center
  font-size: 20px

.q-img__content
  .markImage.active
    border-width: 2px !important
    border-style: dashed !important
    border-color: hsla(0, 95%, 35%, 1) !important
.q-img__content > div
    background: rgba(0,0,0,0) 
// .vdr 
//   border: 3px dashed #000

.mainMarkImage
  padding: 0px !important
  text-align: center
  font-size: 20px
  // height: 30px !important
  position: static
  padding: 16px
  color: blue
  // background: rgba(0,0,0,0)
  .markImage-abbreviation-parent
    // width:240px
    text-align: start
    position: absolute
    .markImage-abbreviation
      // cursor: pointer
      display: inline-block
      // min-width:60px
      // max-width:250px
      width: auto
      border-left: 1px solid #757575
      border-right: 1px solid #757575
      border-top: 1px solid #757575
      border-bottom: 1px solid #757575
      .markImage-abbreviation-banner
        border-right: 1px solid #757575
        display: inline-block
        text-align: center
        min-width:60px
        i
          font-size: 25px
      .markImage-abbreviation-banner.last-banner
        border-right: 0
      .markImage-abbreviation-plus
        display: inline-block
        text-align: center
        min-width:40px
      .markImage-abbreviation-banner-detail
        padding: 1px !important
.caption-work
  font-size: 0.5em
  font-style: italic
  white-space: normal
  color: #555

.caption-header
  font-size: 0.75em
  font-style: italic
  white-space: normal
  color: #555

.map-carousel
  height: var(--map-height) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__slide
    width: calc(var(--map-ratio) * var(--map-height)) !important
  .q-carousel--padding, .q-carousel__slide
    padding: 0 !important
  .q-carousel__arrow
    transition: all 0.5s
    opacity: 0.2
  .q-carousel__arrow:hover
    transition: all 0.5s
    opacity: 0.8
  .q-carousel__slides-container
    .q-panel.scroll
      overflow: hidden !important

</style>

<style scoped>

.mapTitleDiv {
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 10px;
  background-color: #283046 !important;
  color: #fff;
  padding: 5px 10px;
  font-weight: 700;
  z-index: 2000;
  font-size: 12px;
}
</style>